<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="商品名称" prop="name">
          <a-input v-model="queryRef.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="供应商" prop="supplyShopId">
          <a-spin class="custom-spin" :spinning="listPartnerLoading" size="small">
            <a-select style='min-width:200px' v-model="queryRef.supplyShopId" placeholder="请选择" allowClear>
              <a-select-option v-for="d in listPartner" :key="d.shopId">
                <span class="custom-select-option">{{d.name}}</span>
              </a-select-option>
            </a-select>
          </a-spin>
        </a-form-model-item>
        <a-form-model-item label="商品分组" prop="productGroupId">
          <a-select v-model="queryRef.productGroupId" placeholder="请选择" style="min-width: 120px">
            <a-select-option v-for="d in productGroupList" :key="d.productGroupId">
              <span class="custom-select-option">{{d.groupName}}</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否用于注册用户" prop="txFlag">
          <a-select v-model="queryRef.txFlag" placeholder="请选择" style="min-width: 120px">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">是</a-select-option>
            <a-select-option value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="注册用户类型" prop="bdFlag">
          <a-select v-model="queryRef.bdFlag" placeholder="请选择" style="min-width: 120px">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">存储</a-select-option>
            <a-select-option value="0">回输</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class="btn-box">
      <a-button type="primary" @click="$router.push('/goods/publish/add')" v-pms="'publish'">
        发布商品
      </a-button>
      <a-button type="primary" v-pms="'collection'" ghost @click="$router.push('/goods/collect_market')">
        采集商品
      </a-button>
      <a-button type='primary' v-pms='"updateState"' ghost :disabled="!selectedRowKeys.length" @click="updateStatus(1)">批量上架</a-button>
      <a-button type='primary' v-pms='"updateState"' ghost :disabled="!selectedRowKeys.length" @click="updateStatus(2)">批量下架</a-button>
      <span style="padding-left:16px;" v-if='selectedRowKeys.length'>已选{{selectedRowKeys.length}}件商品</span>
    </div>
    <base-tabs :tabs="stateList" :active="queryRef.state" @change="handleStateChange" />
    <base-table bordered :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.productId" :loading="loadingRef" @change="handleTableChange" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
      <template #goodsName="record">
        <router-link tag="div" class="goods-info goods" :to="`/goods/goods_detail/${record.productId}`">
          <base-img class="goods-img" :src="record.productImg" width="60" height="60" oss_style="60_60" />
          <a-tooltip :title="record.name">
            <p class="goods-name el2">{{record.name}}</p>
          </a-tooltip>
        </router-link>
      </template>
      <template #shopInfo="record">
        <div class="goods-info">
          <base-img class="goods-img" :src="record.shopLogo" width="60" height="60" oss_style="60_60" />
          <div>
            <p @click="goShopDetail(record.collectShopId)" class="goods-name shop-name el2">{{record.shopName}}</p>
            <span :style="{fontSize: '13px', color: shopAuthStateFormat(record.shopAuthState, 'color')}">{{shopAuthStateFormat(record.shopAuthState, 'text')}}</span>
          </div>
        </div>
      </template>
      <template #supplyPrice="record">
        <p>{{record.supplyPrice}}</p>
      </template>
      <template #tradePrice="record">
        <p>{{record.tradePrice}}</p>
        <p v-show="record.tradePriceMarkup">加价率：{{record.tradePriceMarkup}}%</p>
      </template>
      <template #operation="record">
        <div class="table-operations">
          <a-button type="link" v-pms="'edit'" @click="$router.push(`/goods/publish/edit/${record.productId}`)">
            编辑
          </a-button>
          <a-popconfirm v-if="record.state === 1 || record.state === 2" v-pms="'updateState'" placement="topRight" :title="`确定${record.state === 1 ? '下架' : '上架'}该商品吗？`" ok-text="确定" cancel-text="取消" @confirm="handleUpdateProductState(record)">
            <a-button v-if="record.state === 1 || record.state === 2" type="link">{{record.state === 1 ? '下架' : '上架'}}</a-button>
          </a-popconfirm>
          <a-popconfirm placement="topRight" :title="`确定删除该商品吗？`" ok-text="确定" cancel-text="取消" @confirm="handleDeleteProduct(record.productId)">
            <a-button type="link">删除</a-button>
          </a-popconfirm>
          <a-button v-if="record.batchFlag" type="link" @click="showBatchModal(record.productId)">批发设置</a-button>
<!--          <a-button type="link" @click="showCommModal(record.productId)">佣金设置</a-button>-->
          <a-popconfirm placement="topRight" :title="`确定复制该商品吗？`" ok-text="确定" cancel-text="取消" @confirm="handleCopyProduct(record.productId)">
            <a-button type="link">复制</a-button>
          </a-popconfirm>
          <a-popover placement='left' v-if="isPlatform">
            <div slot="content" class='flex-a-c'>
              <div>{{`/pages/goodsDetail/goodsDetail?productId=${record.productId}`}}</div>
              <a-button type='link'  v-clipboard:copy="`/pages/goodsDetail/goodsDetail?productId=${record.productId}`" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">复制</a-button>
            </div>
            <a-button type="link">商品链接</a-button>
          </a-popover>
        </div>
      </template>
    </base-table>

    <modal-batch-setting :productId="batchingProductId" :visible.sync="showBatchModalFlag" />
    <modal-comm-setting :productId="commProductId" :visible.sync="showCommModalFlag" />
  </div>
</template>

<script>
import { ref, onUnmounted, onMounted, reactive, toRefs } from '@vue/composition-api'
import { useTableList } from '@/hooks'
import { goods, common } from '@/api'
import { shopAuthStates, productStates } from '@/utils/constants'
import NP from 'number-precision'
import { SHOPID } from '../../constants'
import { getSession } from '@/utils/session'
import ModalBatchSetting from './ModalBatchSetting'
import ModalCommSetting from './ModalCommSetting'
import { isPlatform } from '../../utils/tools'
const stateList = [
  { title: '全部', value: '' },
  { title: '销售中', value: '1' },
  { title: '已售罄', value: '4' },
  { title: '未上架', value: '2' },
]
const columns = [
  {
    title: '商品名称',
    scopedSlots: {
      customRender: 'goodsName',
    },
  },
  {
    title: '是否用于注册用户',
    dataIndex: 'txFlag',
    customRender: x => {
      return x === true ? '是' : '否'
    }
  },
  {
    title: '注册用户类型',
    dataIndex: 'bdFlag',
    customRender: x => {
      return x === true ? '存储' : '回输'
    }
  },
  {
    title: '供应商',
    scopedSlots: {
      customRender: 'shopInfo',
    },
  },
  {
    title: '成本价',
    scopedSlots: {
      customRender: 'supplyPrice',
    },
  },
  {
    title: '供货价',
    scopedSlots: {
      customRender: 'tradePrice',
    },
  },
  {
    title: '库存',
    dataIndex: 'stock',
  },
  {
    title: '销量',
    dataIndex: 'sales',
  },
  {
    title: '商品状态',
    dataIndex: 'state',
    customRender: (x) => {
      const matching = productStates.find((j) => j.value === +x)
      return matching ? matching.name : ''
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
export default {
  name: 'PageGoodsList',
  components: {
    ModalBatchSetting,
    ModalCommSetting,
  },
  setup (props, { root }) {
    const callback = () => {
      refresh()
    }
    root.$bus.$on('PageGoodsList:refresh', callback)
    onUnmounted(() => {
      root.$bus.$off('PageGoodsList:refresh', callback)
    })
    const state = reactive({
      productGroupList: [],
      listPartner: [],
      listPartnerLoading: false,
      selectedRowKeys: []
    })
    onMounted(async () => {
      getListPartner()
      getProductGroupList()
    })

    const formRef = ref(null)
    const queryRef = ref({
      name: '',
      state: '',
      supplyShopId: undefined,
      productGroupId: undefined,
      bdFlag: undefined,
      txFlag: undefined,
    })
    const { dataRef, pageRtv, loadingRef, resetPage, setPage, refresh } = useTableList(
      goods.getGoodsList,
      queryRef,
      true,
      (res) =>
        res
          ? res.map((x) => ({
            ...x,
            shopAuthState: typeof x.shopAuthState === 'undefined' ? 0 : x.shopAuthState,
            tradePriceMarkup: x.tradePriceMarkup ? NP.times(x.tradePriceMarkup, 100) : '',
          }))
          : []
    )

    function handleSearch () {
      resetPage()
      refresh()
    }

    function handleReset () {
      formRef.value.resetFields()
      handleSearch()
      console.log(dataRef)
    }

    function handleTableChange ({ current }) {
      setPage(current)
    }

    function handleStateChange (state) {
      queryRef.value.state = state
      handleSearch()
    }
    async function getListPartner () {
      state.listPartnerLoading = true
      const { data } = await common.getListPartner()
      state.listPartnerLoading = false
      state.listPartner = data || []
      if (
        root.$store.getters['head/getShopInfo'].name &&
        root.$store.getters['head/getShopInfo'].shopId
      ) {
        state.listPartner.push({
          name: root.$store.getters['head/getShopInfo'].name,
          shopId: root.$store.getters['head/getShopInfo'].shopId,
        })
      }
    }
    async function getProductGroupList () {
      const { data } = await common.getProductGroupByShopId()
      state.productGroupList = data || []
    }

    function onSelectChange (selectedRowKeys, selectedRows) {
      state.selectedRowKeys = selectedRowKeys
      console.log(state.selectedRowKeys)
    }
    function shopAuthStateFormat (state, type) {
      if (!state) return ''
      const matching = shopAuthStates.find((x) => x.value === +state)
      if (type === 'text') return matching ? matching.name : ''
      if (type === 'color') return matching ? matching.color : ''
    }

    async function handleUpdateProductState ({ productId, state, name }) {
      loadingRef.value = true
      const { code, msg, data } = await goods.updateProductState({
        productId,
        state: state === 1 ? 2 : 1,
        shopName: root.$store.getters['head/getShopInfo'].name,
        productName: name,
      })
      loadingRef.value = false
      if (code !== '00000') return root.$message.error(msg || '提交失败，请稍后重试')
      root.$success({
        title: state === 1 ? '上架操作结果' : '下架操作结果',
        content: data
      })
      refresh()
    }

    async function updateStatus (s) {
      loadingRef.value = true
      const { code, msg, data } = await goods.updateProductState({
        productId: state.selectedRowKeys.join(','),
        state: s,
        shopName: root.$store.getters['head/getShopInfo'].name,
      })
      loadingRef.value = false
      if (code !== '00000') return root.$message.error(msg || '提交失败，请稍后重试')
      root.$success({
        title: s === 1 ? '批量上架结果反馈' : '批量下架结果反馈',
        content: data
      })
      state.selectedRowKeys = []
      refresh()
    }

    function goShopDetail (id) {
      if (id === getSession(SHOPID)) {
        root.$router.push('/shop/info')
      } else {
        root.$router.push('/goods/shop_goods_list/' + id)
      }
    }
    async function handleDeleteProduct (id) {
      const { code, msg } = await goods.deleteProduct({ productId: id })
      if (code === '00000') {
        root.$message.success('删除成功')
        handleReset()
      } else {
        root.$message.error(msg || '删除成功')
      }
    }
    async function handleCopyProduct (id) {
      const { code, msg } = await goods.copyProduct({ productId: id })
      if (code === '00000') {
        root.$message.success('复制成功')
        handleReset()
      } else {
        root.$message.error(msg || '复制成功')
      }
    }

    const showBatchModalFlag = ref(false)
    const batchingProductId = ref('')
    function showBatchModal (id) {
      batchingProductId.value = id
      showBatchModalFlag.value = true
    }
    const showCommModalFlag = ref(false)
    const commProductId = ref('')
    function showCommModal (id) {
      commProductId.value = id
      showCommModalFlag.value = true
    }
    function onCopySuccess () {
      root.$message.success('复制成功')
    }
    function onCopyError () {
      root.$message.error('复制失败')
    }

    return {
      isPlatform,
      ...toRefs(state),
      stateList,
      formRef,
      queryRef,
      columns,
      dataRef,
      pageRtv,
      loadingRef,
      handleSearch,
      handleReset,
      handleTableChange,
      handleStateChange,
      shopAuthStateFormat,
      handleUpdateProductState,
      goShopDetail,
      handleDeleteProduct,
      handleCopyProduct,
      showBatchModal,
      showCommModal,
      showBatchModalFlag,
      batchingProductId,
      showCommModalFlag,
      commProductId,
      onCopySuccess,
      updateStatus,
      onCopyError,
      onSelectChange
    }
  },
}
</script>

<style lang="less" scoped>
.btn-box {
  margin-bottom: 18px;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
.goods-info {
  display: flex;
  align-items: center;
  &.goods {
    cursor: pointer;
  }
  .goods-img {
    flex: none;
    margin-right: 12px;
  }
  .goods-name {
    flex: 0 1 auto;
    max-width: 126px;
    font-size: 14px;
    line-height: 22px;
    color: #0066ff;
    &.shop-name {
      color: #0066ff;
      cursor: pointer;
    }
  }
}
.table-operations ::v-deep .ant-btn {
  padding: 0 5px;
}
.flex-a-c {
  display: flex;
  align-items: center;
}
</style>
